import { Link } from "gatsby";
import React from "react";
import { Button } from "../elements/Button";
import { Form } from "../elements/Form";
import { Input } from "../elements/Input";
import { Text } from "../elements/Text";
import { CHECKOUT_URL } from "../utils/constants";

const LoginForm = (props) => {
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
    errorMessage: null,
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { email, password } = formData;
    if (!email || !password) {
      setFormData({
        ...formData,
        errorMessage: "You must provide both fields",
      });

      return false;
    }

    formData.email = formData.email.toLowerCase();

    props.onLogin(formData);
  };

  return (
    <Form bg="white" py={3} px={3} textAlign="center" width="100%" maxWidth="500px" mx="auto">
      {props.errorMessage && (
        <Text color="red" fontWeight={600} mt={3}>
          {props.errorMessage}
        </Text>
      )}

      <Text my={3} fontSize={25} fontWeight={600}>
        LOGIN
      </Text>

      <Input
        variant="authentication"
        id="email"
        placeholder="Email"
        type="email"
        required
        value={formData.email}
        onChange={handleChange}
      />

      <Input
        variant="authentication"
        id="password"
        placeholder="Password"
        type="password"
        required
        value={formData.password}
        onChange={handleChange}
      />

      <Button
        bg="pink"
        width="100%"
        color="white"
        p={2}
        border="none"
        borderRadius="5px"
        fontWeight="600"
        onClick={handleSubmit}
        fontSize={17}
      >
        Login
      </Button>
      {formData.errorMessage && (
        <Text color="red" fontWeight={600} mt={3}>
          {formData.errorMessage}
        </Text>
      )}

      <Text mt={3}>
        Don't have an account? <Link to={CHECKOUT_URL}>Sign up now</Link>
      </Text>

      <Text mt={3}>
        Forgot password? <Link to={`${CHECKOUT_URL}/forgot-my-password`}>Reset</Link>
      </Text>
    </Form>
  );
};

export default LoginForm;

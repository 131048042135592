import React from "react";
import LoginForm from "../components/LoginForm";
import { Flex } from "../elements/Flex";
import { login } from "../utils/auth";

const LoginPage = () => {
  const [state, setState] = React.useState({
    errorMessage: "",
  });


  const onLogin = async (formData) => {
    try {
      await login(formData);
    } catch (e) {
      console.log(e);
      if (e.code === "UserNotFoundException" || e.code === "NotAuthorizedException") {
        setState({
          ...state,
          errorMessage: "Incorrect email/password combination",
        });
        return false;
      }
    }
  };

  return (
    <Flex bg="pink" px={3} py={6}>
      <LoginForm errorMessage={state.errorMessage} onLogin={onLogin} />
    </Flex>
  );
};

export default LoginPage;
